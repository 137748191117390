var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { FetchHttpHandler } from '@aws-sdk/fetch-http-handler';
import { StsClient } from './StsClient';
import { CRED_KEY } from '../utils/constants';
import { Authentication } from './Authentication';
var BasicAuthentication = /** @class */ (function (_super) {
    __extends(BasicAuthentication, _super);
    function BasicAuthentication(config) {
        var _this = _super.call(this, config) || this;
        /**
         * Provides credentials for an anonymous (guest) user. These credentials are retrieved from Cognito's basic
         * (classic) authflow.
         *
         * See https://docs.aws.amazon.com/cognito/latest/developerguide/authentication-flow.html
         *
         * Implements AwsCredentialIdentityProvider = Provider<AwsCredentialIdentity>
         */
        _this.AnonymousCognitoCredentialsProvider = function () { return __awaiter(_this, void 0, void 0, function () {
            var retries, getIdResponse, getOpenIdTokenResponse, credentials, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        retries = 1;
                        _a.label = 1;
                    case 1:
                        if (!true) return [3 /*break*/, 8];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 6, , 7]);
                        return [4 /*yield*/, this.cognitoIdentityClient.getId({
                                IdentityPoolId: this.config.identityPoolId
                            })];
                    case 3:
                        getIdResponse = _a.sent();
                        return [4 /*yield*/, this.cognitoIdentityClient.getOpenIdToken(getIdResponse)];
                    case 4:
                        getOpenIdTokenResponse = _a.sent();
                        return [4 /*yield*/, this.stsClient.assumeRoleWithWebIdentity({
                                RoleArn: this.config.guestRoleArn,
                                RoleSessionName: 'cwr',
                                WebIdentityToken: getOpenIdTokenResponse.Token
                            })];
                    case 5:
                        credentials = _a.sent();
                        this.credentials = credentials;
                        try {
                            localStorage.setItem(CRED_KEY, JSON.stringify(credentials));
                        }
                        catch (e) {
                            // Ignore
                        }
                        return [2 /*return*/, credentials];
                    case 6:
                        e_1 = _a.sent();
                        if (retries) {
                            retries--;
                        }
                        else {
                            throw e_1;
                        }
                        return [3 /*break*/, 7];
                    case 7: return [3 /*break*/, 1];
                    case 8: return [2 /*return*/];
                }
            });
        }); };
        var region = config.identityPoolId.split(':')[0];
        _this.stsClient = new StsClient({
            fetchRequestHandler: new FetchHttpHandler(),
            region: region
        });
        return _this;
    }
    return BasicAuthentication;
}(Authentication));
export { BasicAuthentication };
